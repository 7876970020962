
import { HasFormErrors } from "@/mixins/has-form-errors";
import { IndexAccountPayload } from "@/store/modules/account.store";
import { TeamFunctions } from "@/store/modules/activity-account.store";
import { activityIsDossier } from "@/utils/model-helpers";
import { Component, Mixins } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
    components: {
        userImage: () => import("@/components/general/user-image.vue"),
        userSelect: () => import("@/components/forms/user-select.vue"),
        AssignUsers: () => import("@/components/dossier/assign-users.vue"),
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class DossierTabTeamSettings extends Mixins(HasFormErrors) {
    @Getter("account/all") accounts!: Account[];
    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;
    @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Promise<Activity>;
    @Getter("activity/viewing") activity!: Activity;

    payload: { [key in TeamFunctions]?: number } = {};

    syncing = false;

    syncingAttempts = 0;

    get isDossier() {
        return activityIsDossier(this.activity);
    }

    get functions() {
        if (!this.isDossier) {
            return [
                {
                    value: TeamFunctions.buyer,
                    label: this.$t("views.account.functions.BUYER"),
                },
            ];
        }

        return Object.values(TeamFunctions).map((f) => {
            return {
                value: f,
                label: this.$t(`views.account.functions.${f.toUpperCase()}`),
            };
        });
    }

    get users() {
        return this.accounts.map((a) => {
            return { first_name: a.first_name, last_name: a.last_name, id: a.id, media: a.media };
        });
    }

    async mounted() {
        if (!this.accounts.length) {
            await this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, per_page: 99 });
        }
    }

    async syncEstates() {
        try {
            this.syncing = true;

            await this.patchActivity({ id: this.activity.id });

            this.syncing = false;

            this.syncingAttempts++;
        } catch (e: any) {
            this.errorResponse = this.formatErrors(e);
        }
    }
}
